/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "./App.css";
import { connect, fetchEnsName, getWalletClient } from "@wagmi/core";
import { InjectedConnector } from "@wagmi/core/connectors/injected";

import { createConfig, configureChains, mainnet } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [publicProvider()]
);

const config = createConfig({
  publicClient,
  webSocketPublicClient,
});

function App() {
  const [walletStr, setWalletStr] = useState("");
  const connectNow = async () => {
    const result = await connect({
      connector: new InjectedConnector(),
    });
    const walletClient = await getWalletClient();
    console.log("walletClient", walletClient?.account.address);
    setWalletStr(walletClient?.account.address as string);
  };

  const planetClick = () => {
    alert("Under Construction 🚀🪐");
  };

  return (
    <div className="App">
      <div className="main"></div>

      <div id="app">
        <img src="assets/logo.png" id="logo" />
        {walletStr === "" ? (
          <div className="connect buttonPlanet" onClick={connectNow}>
            <div className="txt">Connect Wallet</div>
          </div>
        ) : (
          <div>
            <div className="txt m0">
              <br />
              <strong>
                Wallet: &nbsp;
                {`${walletStr.substring(0, 7)}...${walletStr.slice(-5)}`}
              </strong>
            </div>
          </div>
        )}

        <div className="sec-title">
          <h1 className="txt-center">Space Wars Presale Launch</h1>
          <span className="bottom-curve"></span>
        </div>
        <div className="boxed-txt">
          Unlock the cosmos with Space Wars presale. Connect your wallet and be
          part of the celestial journey.
        </div>

        <div className="miniGalaxy">
          <div className="planet planet-1">
            {/* <div className="textPlanet">Common Box</div> */}
            <div className="buttonPlanet">Common</div>
          </div>
          <div className="planet planet-2">
            {/* <div className="textPlanet">Rare Box</div> */}
            <div className="buttonPlanet">Rare</div>
          </div>
          <div className="planet planet-3">
            {/* <div className="textPlanet">Epic Box</div> */}
            <div className="buttonPlanet">Epic</div>
          </div>

          <div id="shipDirection">
            <div id="spaceShip" className="ship" planet-slot="0">
              <img src="assets/flame.gif" className="flame_ship" />
              <img src="assets/Light_Spaceship_1.png" className="ship_1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
